.dropdown{
    position: relative;
    display: inline;
}

.dropdown-content{
    position: absolute;
    display: none;
}

.show{
    display: block;
}

#list li {
    background-color: #e4e5eb;
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
