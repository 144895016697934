#nav{
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

#nav nav{
    height: 100px;
}

#menu-btn {
    display: none;
}

.show {
    display: block;
}

.hide {
    display: none;
}

@media (max-width: 990px) {
    #con-nav {
        position: relative;
        width: 100vw;
    }

    #navbar {
        position: absolute;
        top: 100%;
        background-color: #fff;
        width: 100%;
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 3%;
        z-index: 20;
    }

    #menu-btn {
        display: block;
    }

}
