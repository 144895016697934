.times-font{
  font-family: 'Times New Roman', Times, serif;
}

.image-card{
  padding: 2%;
}

#appointment{
    color:#fff;
}

.shadow{
    box-shadow: #000 2px;
}

#extra-block{
  background-color: rgb(177, 175, 175);
  color: #fff;
}

#hero {
    width: 100%;
    height: 60vh;
    background-image: url('../dawson-street.jpg');
    height: 200px; /* You must set a specified height */
    background-attachment: fixed;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

/* #hero {
  background-image: none;
  width: 100%;
  height: 150px;
  text-align: center;
  background-attachment: local;
} */

#hero-text {
  color: #fff; /* Ensure the text is white or another contrasting color */
  width: 100%;
  margin: 30px;
  color: rgb(73, 72, 72); /* Ensure the text is white or another contrasting color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Adjust padding as needed */
  background: rgba(255,255,255,0.8 ); 
  border-radius: 10px; /* Optional: Adds rounded corners to the background */
}

#hero-two{
    width: 100%;
    padding: 3%;
    font-weight: 600;
    background-color: #0e1267e7;
    color: #000;
}

#parallax{
    background-image: url('../dawson-street.jpg');
    height: 500px; /* You must set a specified height */
    background-attachment: fixed;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

/*--------------------------------------------------------------
# Our Services Section
--------------------------------------------------------------*/
.services {
    padding: 60px 0;
  }
  
  .services .service-item {
    padding: 40px;
    background: #fff;
    height: 100%;
    border-radius: 10px;
  }
  
  .services .service-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 50px;
  }
  
  .services .service-item .icon i {
    color: var(--color-default);
    font-size: 56px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
  }
  
  .services .service-item .icon:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #eeeeee;
    border-radius: 50px;
    z-index: 1;
    top: 10px;
    right: -20px;
    transition: 0.3s;
  }
  
  .services .service-item h3 {
    color: var(--color-default);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid #eeeeee;
    transition: 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .service-item .readmore {
    margin-top: 15px;
    display: inline-block;
    color: var(--color-primary);
  }
  
  .services .service-item:hover .icon:before {
    background: #000aad;
  }
  
  .services .service-item:hover h3 {
    border-color: var(--color-primary);
  }

  #map {
    width: 100%;
  }

  .white{
    color: #fff;
  }

  #footer{
    background-color: #0e1367;
    color:#fff;
    font-family: "Times New Roman", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .playfair-display {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  #footer a{
    text-decoration: none;
    color:#fff;
  }

  #pageNotFound{
    height: 80vh;
  }

  @media (max-width: 990px) {
    #hero {
      /* background-image: none; */
      height: 150px;
      text-align: center;
      background-attachment: local;
  }
  
  #hero-text {
    color: rgb(73, 72, 72); /* Ensure the text is white or another contrasting color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px; /* Adjust padding as needed */
    background: rgba(255,255,255,0.8 ); /* Semi-transparent background */
    display: none;
  }
  }
