.carousel {
  position: relative;
  width: 100vw;
  overflow: hidden;
  margin: auto;
  background-color: rgb(31, 30, 30);
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  /* transition: opacity 0.5s ease-in-out; */
}

.carousel-item img {
  width: 100%;
}

.carousel-item:not(.active) {
  display: none;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.025);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

.carousel-image {
  height: 400px;
  width: 20vw;
  /* object-fit: cover; */
  filter: brightness(40%);
}

.carousel-text {
  width: 50%;
  position: absolute;
  bottom: 180px;
  left: 2vw;
  color: #000;
  background: rgb(255, 255, 255);
  padding: 15px;
  border: 1px solid #000;
}