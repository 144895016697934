#app {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.playfair-display {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

#img-contact {
  background-image: url('dawson-street.jpg');
  height: 500px; /* You must set a specified height */
  background-attachment: fixed;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

@media (max-width=600px){
    #navbar{
      border: 1px solid;
    }
}